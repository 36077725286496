import axios from "axios";
import React, { useState } from "react";
import { ProductCard } from "./ProductCard";

const ProductSelection = ({
  userId,
  token,
  url,
  purchasedPhysical,
  physical,
  workshop,
  mndCareDisc,
  mndResearchDisc,
  mndCareOnly,
  mndResearchOnly,
  sst,
}) => {
  const [selected, setSelected] = useState(null);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);
  const [selectedMndCareDisc, setSelectedMndCareDisc] = useState(null);
  const [selectedMndResearchDisc, setSelectedMndResearchDisc] = useState(null);
  const [selectedMndCareOnly, setSelectedMndCareOnly] = useState(null);
  const [selectedMndResearchOnly, setSelectedMndResearchOnly] = useState(null);

  const disabledSubmit = !(
    selected ||
    selectedMndCareOnly ||
    selectedMndResearchOnly ||
    selectedWorkshop ||
    selectedMndCareDisc ||
    selectedMndResearchDisc
  );

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="product_ids"
          value={[
            selected,
            selectedWorkshop,
            selectedMndCareDisc,
            selectedMndResearchDisc,
            selectedMndCareOnly,
            selectedMndResearchOnly,
          ]}
        />

        {/* physical */}
        <ProductCard
          cardTitle={"PACTALS Congress 2025"}
          products={physical}
          selectedState={selected}
          setSelectedState={setSelected}
          activated={!purchasedPhysical}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelected(null);
              setSelectedWorkshop(null);
              setSelectedMndCareDisc(null);
              setSelectedMndResearchDisc(null);
            }
          }}
          handleRadio={() => {
            setSelectedMndCareOnly(null);
            setSelectedMndResearchOnly(null);
          }}
        />

        {/* workshop */}
        {/* must select physical first or purchasedPhysical*/}
        {(selected || purchasedPhysical) && (
          <ProductCard
            cardTitle={"Congress Workshops"}
            products={workshop}
            selectedState={selectedWorkshop}
            setSelectedState={setSelectedWorkshop}
            activated={true}
            handleCheckbox={(e) => {
              if (!e.target.checked) {
                setSelectedWorkshop(null);
              }
            }}
            handleRadio={() => {}}
          />
        )}

        {/* mndCareDisc */}
        {/* must select physical first or purchasedPhysical*/}
        {(selected || purchasedPhysical) && (
          <ProductCard
            cardTitle={"MND Care Conference"}
            products={mndCareDisc}
            selectedState={selectedMndCareDisc}
            setSelectedState={setSelectedMndCareDisc}
            activated={true}
            handleCheckbox={(e) => {
              if (!e.target.checked) {
                setSelectedMndCareDisc(null);
              }
            }}
            handleRadio={() => {}}
          />
        )}

        {/* mndResearchDisc */}
        {/* must select physical first or purchasedPhysical*/}
        {(selected || purchasedPhysical) && (
          <ProductCard
            cardTitle={"MND Research Conference"}
            products={mndResearchDisc}
            selectedState={selectedMndResearchDisc}
            setSelectedState={setSelectedMndResearchDisc}
            activated={true}
            handleCheckbox={(e) => {
              if (!e.target.checked) {
                setSelectedMndResearchDisc(null);
              }
            }}
            handleRadio={() => {}}
          />
        )}

        {/* mndCareOnly */}
        {/* without select physical or not purchasedPhysical*/}
        {!selected && !purchasedPhysical && (
          <ProductCard
            cardTitle={"MND Care Conference"}
            products={mndCareOnly}
            selectedState={selectedMndCareOnly}
            setSelectedState={setSelectedMndCareOnly}
            activated={true}
            handleCheckbox={(e) => {
              if (!e.target.checked) {
                setSelectedMndCareOnly(null);
              }
            }}
            handleRadio={() => {}}
          />
        )}

        {/* mndResearchOnly */}
        {/* without select physical or not purchasedPhysical*/}
        {!selected && !purchasedPhysical && (
          <ProductCard
            cardTitle={"MND Research Conference"}
            products={mndResearchOnly}
            selectedState={selectedMndResearchOnly}
            setSelectedState={setSelectedMndResearchOnly}
            activated={true}
            handleCheckbox={(e) => {
              if (!e.target.checked) {
                setSelectedMndResearchOnly(null);
              }
            }}
            handleRadio={() => {}}
          />
        )}

        {sst && (
          <strong className="d-block mx-auto">
            All purchases will incur 8% SST
          </strong>
        )}

        <button
          type="submit"
          className="btn text-white btn-primary d-block mx-auto mt-4"
          disabled={disabledSubmit}
        >
          Purchase Pass
        </button>
      </form>
    </div>
  );
};

export default ProductSelection;
